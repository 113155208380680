@charset "UTF-8";

// Required
header {@include page-layout;}

@if $boxed{
	.header.variant1 .topWrapper,
	.header.variant1 .bottomWrapper {margin:0 auto;max-width:$frame-width;}
	header .header.variant1 .bottomWrapper.fixed {max-width:$frame-width;left:50%;margin-left:-$frame-width/2;}
}

@if $header-hamburger-text-visible{
	
}@else {
	.header.variant1 .top-bar .toggle-topbar.menu-icon a  span{font-size:0px;color:transparent}
}	

// Variantstyling
header {z-index:9999;position:relative;max-width:100%;}
header .header.variant1 .fixed.fade {animation-duration: 0.3s;animation-name: fadeIn;}
header .header.variant1 .bottomWrapper.fixed {z-index:9999;}
header .header.variant1 .fixed .top-bar {max-width:$frame-width;margin:0 auto;}
.header.variant1 .topWrapper {background:$header-top-background;border-bottom:1px solid lighten($border-color, 8);padding-top:$padding-small;padding-bottom:$padding-small;overflow:hidden;border-bottom:$header-top-border-bottom;}
.header.variant1 .top {@include page-layout;}
.header.variant1 .topBar,
.header.variant1 .topBar a {color:$header-topbar-color;}
.header.variant1 .topBar .languageBtns {line-height:1.6;}
.header.variant1 .topBar .languageBtns a.active {cursor:default;color:$topbar-link-color-active;font-weight:$topbar-link-active-weight;}
.header.variant1 .topBar .logoutBtn #Block_Header_Uitloggen_ctl00:before {content:"\f09c";font-family:'FontAwesome';margin-right:$margin-small;}
.header.variant1 .topBar .logoutBtn #Block_Header_Uitloggen_pnlLogout,
.header.variant1 .topBar .logoutBtn a {display:inline-block!important;}
.header.variant1 .bottomWrapper{border-bottom:$header-bottom-border-bottom;background:$header-bottom-background;}
.header.variant1 .bottomWrapper .top-bar-section ul {margin:$header-nav-margin;}
.header.variant1 .bottomWrapper .top-bar-section ul li{background:$header-bottom-background; margin:$header-nav-li-margin;}
.header.variant1 .bottom {@include page-layout;background:$header-bottom-background;z-index:9999;}
.header.variant1 .bottom .logo img {height:60px;width:auto;margin-top:$margin-normal;}
.header.variant1 .bottom .toggle-topbar.menu-icon + .left {max-width:calc(100% - 100px); height: 100%;}
.header.variant1 .bottom .toggle-topbar.menu-icon + .left img {max-height:60px; height:auto;}
.header.variant1 .bottom .toggle-topbar.menu-icon + .left ul {display:inline-block; vertical-align:middle;}
.header.variant1 .top-bar .toggle-topbar.menu-icon {top:0;margin-top:rem-calc(30);}
.header.variant1 .top-bar .toggle-topbar.menu-icon a {z-index:100;color:$header-hamburger-text-color;}
.header.variant1 .bottomWrapper .top-bar li.active > a {color:$header-nav-active-color;}
.header.variant1 .top-bar-section ul li > a,
.header.variant1 .top-bar-section ul li > span {padding:$padding-normal!important;line-height:$header-nav-line-height !important;display:block;overflow:hidden;font-size:$header-nav-font-size;font-weight:$header-nav-font-weight;font-family:$header-nav-font-family;}
.header.variant1 .top-bar.expanded .top-bar-section ul {margin-top:$margin-normal;margin-bottom:$margin-normal;}
.header.variant1 .top-bar.expanded .top-bar-section ul li > a,
.header.variant1 .top-bar.expanded .top-bar-section ul li > span {line-height:1!important;border-bottom:1px solid $border-color;padding:$padding-normal 0!important;font-size:$header-nav-font-size;font-weight:$header-nav-font-weight;}
.header.variant1 .top-bar.expanded .top-bar-section ul.dropdown{border:0;top:90px;border-bottom:1px solid #ddd;}
.header.variant1 .top-bar.expanded .top-bar-section ul.dropdown li{overflow:hidden;}
.header.variant1 .top-bar.expanded .top-bar-section ul.dropdown li a{text-align:left;padding:$padding-normal 0 $padding-normal $padding-normal*2!important;border:0;}
.header.variant1 .top-bar.expanded .top-bar-section ul.dropdown li.parent-link{}
.header.variant1 .top-bar.expanded .top-bar-section ul.dropdown li.parent-link a{color:$header-nav-active-color!important;padding:$padding-normal!important;}
.header.variant1 .top-bar.expanded .top-bar-section ul.dropdown li.first{border-color:#ddd}
.header.variant1 .top-bar.expanded .top-bar-section ul.dropdown li.title.back{overflow:hidden;}
.header.variant1 .top-bar.expanded .top-bar-section ul.dropdown li.title.back a{width:100%;background:$primary-color;color:#fff;padding:$padding-normal!important;}
.header.variant1 .top-bar .toggle-topbar a span:after {box-shadow:0 0 0 1px $header-hamburger-color, 0 8px 0 1px $header-hamburger-color, 0 16px 0 1px $header-hamburger-color;}
.header.variant1 .top-bar-section > ul > li.last > a,
.header.variant1 .top-bar-section > ul > li.last > span {padding-right:$header-nav-last-padding;}
.header.variant1 .top-bar-section .dropdown {border:$header-dropdown-border;border-top:0;}
.header.variant1 .top-bar-section .dropdown li {border-top:$header-dropdown-link-border-top;text-align:right;}
.header.variant1 .top-bar-section .dropdown li.first {border-color: $primary-color;}
.header.variant1 .top-bar-section .dropdown li.active {background:$header-dropdown-active-link-bg;}
.header.variant1 .top-bar-section .dropdown li.active a {color:$header-dropdown-active-link-color;}
.header.variant1 .top-bar-section .dropdown li:not(.has-form):not(.active) > span:not(.button) {background:$header-dropdown-link-bg;color:$header-dropdown-link-color;white-space:nowrap;}
.header.variant1 .top-bar-section .dropdown li a,
.header.variant1 .top-bar-section .dropdown li span {float:left;line-height:1!important;}
.header.variant1 .top-bar-section .has-dropdown .dropdown li.has-dropdown > a::after {display: none;}
.header.variant1 .topBar,
.header.variant1 .topBar p{font-size:$header-topbar-font-size;float:left;margin:0;}
.header.variant1 .topBar .fa {font-size:$header-topbar-font-size;}
.header.variant1 .topBar a {border-left:1px solid $border-color;margin-left:rem-calc(10);padding-left:rem-calc(10);font-size:$header-topbar-font-size;}
.header.variant1 .logoutBtn a,
.header.variant1 a.loginBtn{border-left:0;margin-left:0;padding-left:0;}
.header.variant1 .topBar a.last {border-right:0;margin-right:0;padding-right:0;}

@media only screen and (min-width:#{lower-bound($large-range)}) and (max-width: $frame-width + 15){
	header .header.variant1 .bottomWrapper.fixed {
		@if $boxed {
			width:100%;left:0;top:0;margin:0;
		}
	}
}

@-webkit-keyframes fadeIn{0%{opacity:0}100%{opacity:1}}@keyframes fadeIn{0%{opacity:0}100%{opacity:1}}

@media #{$small-only} {
	.header.variant1 .bottom .toggle-topbar.menu-icon + .left img {margin-top:0;}
}