@charset "UTF-8";

.myPersonalInformation.variant1{
	.SN_NietTonen {float:left; margin-right:15px;}
	label {display:inline; }
	.add_mutation_link {display:block;}
	table {max-width:500px;}
	input[type=button] {font-size:$main-font-size;@include button;float:left;}
	input[id^="Block_EditProfile_SN_Medewerker_RadioButton_"] {margin-right:5px;}
	.mutation_link + span[bestemming_id] + label {margin-left:5px;}
}

@media only screen and (max-width: 40em) {
	.myPersonalInformation.variant1 .SN_Overview table {
		td {display:block;
		&:first-child {font-weight:bold;}
		}
	}
}