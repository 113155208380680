@charset "UTF-8";

.personPersonalInformation.variant1 {
	.SN-Block-PersonalInformation {display:table;}
	.SN-Block-PersonalInformation-Item {display:table-row;
		strong {display:table-cell; padding-right:15px; font-weight:$block-person-personal-information-bold-titles; border-bottom:$block-person-personal-information-underline;}
		span {display:table-cell; border-bottom:$block-person-personal-information-underline;}
	}
	.SN-Block-PersonalInformation-Title {font-weight:bold;}
}

@media only screen and (max-width: 40em) {
	.personPersonalInformation.variant1 {
		.SN-Block-PersonalInformation-Item {display:table-row;
			strong, span {display:block; clear:both; float:none;}
		}
	}
}