@charset "UTF-8";

.changePersonInformation.variant1 .element_container {display:block;overflow:hidden;clear:both;}
.changePersonInformation.variant1 .input_container input.button {@include button;}
.changePersonInformation.variant1 .input_container {float:left;margin-right:$margin-small;width:90%;clear:left;}
.changePersonInformation.variant1 .label_container {clear:left;margin-bottom:$margin-small;}
.changePersonInformation.variant1 .fouten {clear:left;border:3px dashed $warning-color;padding:$padding-normal;color:$warning-color !important;margin-bottom:$margin-normal;width:90%;}
.changePersonInformation.variant1 .fouten ul {margin-bottom:0;}
.changePersonInformation.variant1 .input_container input.hasDatepicker {float:left;width:calc(100% - 45px);margin-right:5px;}
.changePersonInformation.variant1 .input_container .ui-datepicker-trigger {cursor:pointer; background: url(/img/1/components/datepickerIcon.png) no-repeat; width: 20px; height: 40px; padding-left: 40px;}
.changePersonInformation.variant1 .fout {float:none; clear:both; display:block; font-style:italic; top:-17px; position:relative;}

/*
.changePersonInformation.variant1 .radio {display:block; margin: 0 0 1rem 0;}
.changePersonInformation.variant1 .radio label {cursor:pointer; position:relative; padding-left:25px; margin-right:15px;}
.changePersonInformation.variant1 .radio input[type=radio] {display:none;}
.changePersonInformation.variant1 .radio input[type=radio] + label {margin-left:0;}
.changePersonInformation.variant1 .radio label:before {content:""; display:inline-block; width:16px; height:16px; margin-right:10px; position:absolute; left:0; bottom:1px; background-color:$primary-color; box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, .3), 0px 1px 0px 0px rgba(255, 255, 255, .8);}
.changePersonInformation.variant1 .radio label:before {border-radius: 8px;}
.changePersonInformation.variant1 .radio input[type=radio]:checked + label:before {width:16px; content:"\2022"; color:#f3f3f3; font-size:30px; text-align:center; line-height:10.5px;}*/
