@charset "UTF-8";

#workgroupDocumentsList ul.documentsList {float:left;clear:both;width:100%;margin:$margin-normal 0 $margin-normal 0;padding:0;}
#workgroupDocumentsList ul.documentsList li {list-style:none;margin-bottom:$margin-normal;padding-bottom:$padding-normal;border-bottom:1px solid $border-color;line-height:1;}
#workgroupDocumentsList ul.documentsList li > a {display:inline-block;}
#workgroupDocumentsList ul.documentsList li > a > p {float:left;margin:0;word-wrap:break-word;word-break:break-all;}
#workgroupDocumentsList ul.documentsList li > a small {display:block;overflow:hidden;color:$main-color;}
#workgroupDocumentsList .fileIcon {float:left;margin-right:$margin-normal;display:inline-block;}

@if $workgroup-documentlist-even-odd-styling {
  #workgroupDocumentsList table.documentsTable tr:nth-of-type(2n) {
    background: transparent;
  }
} @else {
  #workgroupDocumentsList table.documentsTable tr {
    background:none!important;
  }
}

#workgroupDocumentsList table.documentsTable{border:none;table-layout:fixed;clear:both;margin-top:$margin-normal;}
#workgroupDocumentsList table.documentsTable td{vertical-align:top;}
#workgroupDocumentsList table.documentsTable td.docIcon{width:rem-calc(55);padding-left:0;}
#workgroupDocumentsList table.documentsTable td.docInfo p{text-overflow:ellipsis;overflow:hidden;margin:0;}
#workgroupDocumentsList table.documentsTable td.docInfo a small{color:$main-color;font-size:75%;}


/* New */
#workgroupDocumentsList .fileIcon {
  background: url('/img/1/fileIcon.png') 5px top / 45px 55px no-repeat transparent;
  width: 50px;
  height: 55px;
  float: left;
  position: relative;
  .label {
    position:absolute;
    top:50%;
    z-index:2;
    background: #555;
    text-transform: uppercase;
    border-radius:2px;
    color:#FFF;
    font-weight:bold;
    font-size:11px;
    max-width: 50px;
    overflow: hidden;
  }
  // DOCUMENT
  &.doc,
  &.docx,
  &.docm,
  &.dotx,
  &.dot,
  &.dotm,
  &.rtf {
    .label {
      background: #24589b;
    }
  }
  // SPREADSHEET
  &.xltx,
  &.xltm,
  &.xlt,
  &.xlsx,
  &.xlsm,
  &.xlsb,
  &.xls,
  &.xlam,
  &.xla,
  &.csv {
    .label {
      background: #1c7346;
    }
  }
  // PRESENTATION
  &.pptx,
  &.pptm,
  &.ppt,
  &.ppsx,
  &.ppsm,
  &.pps,
  &.ppam,
  &.ppa,
  &.potx,
  &.potm{
    .label {
      background: #d34828;
    }
  }
  // EMAIL
  &.msg,
  &.ics,
  &.oft {
    .label {
      background: #1772b9;
    }
  }
  // PDF
  &.pdf {
    .label {
      background: #c90909;
    }
  }
  // IMAGES
  &.webp,
  &.jpeg,
  &.jpg,
  &.png,
  &.gif,
  &.bmp,
  &.eps,
  &.tiff,
  &.tif {
    .label {
      background: #f2ce29;
    }
  }
  // MOVIE
  &.webm,
  &.avi,
  &.mpg,
  &.mpeg,
  &.mp4,
  &.mov,
  &.flv,
  &.swf {
    .label {
      background: #68428b;
    }
  }
}
