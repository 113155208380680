@charset "UTF-8";

.changeUsername.variant1{
  .element_container {display:block;overflow:hidden;clear:both;}
  .element_container:nth-of-type(1) {display:none!important;}
  .input_container {
    float:left;
    margin-right:$margin-small;
    width:90%;
    clear:left;
    input.button {
      @include button;
    }
  }
  .label_container {clear:left;margin-bottom:$margin-small;}
  span[id*='Block_ChangeUsername_lblCurrentUsername'] {float:left;margin-top:$margin-small;margin-bottom:$margin-normal;background:$border-color;color:$main-color;padding:$padding-normal;border:3px dashed $main-color;}
  .fouten {
    clear:left;
    border:3px dashed $warning-color;
    padding:$padding-normal;
    color:$warning-color !important;
    margin-bottom:$margin-normal;
    width:90%;
    ul{
      margin-bottom:0;
    }
  }
  .validation_container{
    .fout{
      clear:left;
      float:left;
    }
  }
}