@charset "UTF-8";

.pagesNavigation.variant1 {
  .active {
    font-weight: bold;
  }

  nav {
    li,
    a {
      margin: 0 10px;
      display:inline-block;
    }
    li{
      margin:0 20px 0 0;
    }
    ul {
      margin: 0 0 5px 0;
    }
  }
  .navMonths {
    span, a {
      margin: 0 10px;
    }
    :first-child {
      margin-left: 0;
    }
  }
  #period {
    margin-top: 15px;
  }
  li {
    display: inline-block;
  }

  ul li{list-style-type:none;border-bottom:$block-navigation-underline;}
  ul li a{padding:$padding-small 0;display:inline-block;}
  ul li.active > a{color:$secondary-color;}
  ul li ul{margin:0;border-top:$block-navigation-underline;list-style-position:inside;}
  ul li ul li{padding-left:$padding-normal;}
  ul li ul li.last{border:0;}
  ul li ul li a{padding:$padding-small 0 $padding-small $padding-small;font-size:$main-font-size * 0.85;}
  ul li ul li.active{color:$secondary-color;}
  ul li ul li.active > a{color:$secondary-color;}
}

