@charset "UTF-8";

.myNewsletters.variant2 {
	[type="checkbox"]:not(:checked),[type="checkbox"]:checked {position: absolute; left: -9999px}
	[type="checkbox"]:not(:checked)+label,[type="checkbox"]:checked+label {position: relative; padding-left: 25px; cursor: pointer}
	[type="checkbox"]:not(:checked)+label:before,[type="checkbox"]:checked+label:before {content: ''; position: absolute; left: 0; top: 2px; width: 17px; height: 17px; border: 1px solid #aaa; background: #f8f8f8; border-radius: 3px; box-shadow: inset 0 1px 3px rgba(0,0,0,0.3) }
	[type="checkbox"]:not(:checked)+label:after,[type="checkbox"]:checked+label:after {content: '\f00c'; position: absolute; top: 1px; left: 2px; font-size: 14px; color: #09ad7e; transition: all .2s; font-family: FontAwesome}
	[type="checkbox"]:not(:checked)+label:after {opacity: 0; transform: scale(0)}
	[type="checkbox"]:checked+label:after {opacity: 1; transform: scale(1) }
	[type="checkbox"]:disabled:not(:checked)+label:before,[type="checkbox"]:disabled:checked+label:before {box-shadow: none; border-color: #bbb; background-color: #ddd}
	[type="checkbox"]:disabled:checked+label:after {color: #999 }
	[type="checkbox"]:disabled+label {color: #aaa}
	[type="checkbox"]:checked:focus+label:before,[type="checkbox"]:not(:checked):focus+label:before {border: 1px dotted $primary-color}
	label:hover:before {border: 1px solid $primary-color !important}
	#divMailinglistSubscriptionsSaveButton input {@include button;clear:left;float:left;}
}