@charset "UTF-8";

.subscribeNewsletter.variant1 table {background:none;}
.subscribeNewsletter.variant1 label,
.subscribeNewsletter.variant1 .question {font-size:1rem;}
.subscribeNewsletter.variant1 .field.radio table{border:0;margin:0;}
.subscribeNewsletter.variant1 .field.radio table td{padding:rem-calc(10) 0;border:none;}
.subscribeNewsletter.variant1 input[type=submit]{@include button;}
.subscribeNewsletter.variant1 .field.captcha {margin:$margin-normal 0;}
.subscribeNewsletter.variant1 .field.captcha > div:first-child,
.subscribeNewsletter.variant1 #input_container_refreshcaptchaimage {float:left;}
.subscribeNewsletter.variant1 #input_container_refreshcaptchaimage input {font-family:'FontAwesome';border:0;background:#ededed;height:50px;color:$black;border-radius:0;margin:0;}
.subscribeNewsletter.variant1 #captcha_description_container {display:none;}
.subscribeNewsletter.variant1 #input_container_captchatextbox {clear:left;width:233px;}
/*.subscribeNewsletterVariant1 #input_container_refreshcaptchaimage input {background:#ededed;border:0;width:200px;color:#000;border-radius:0;margin:0;}*/


// content:"\f021"