@charset "UTF-8";

.myNewsletters.variant1 label{position:relative;padding-left:30px;}
.myNewsletters.variant1 input[type=checkbox]{display:none;}
.myNewsletters.variant1 input[type=checkbox] + label:before{animation-duration: 0.5s;content:"+";float:left;line-height:0.8;transform:rotate(45deg);-ms-transform:rotate(45deg);-webkit-transform:rotate(45deg);font-weight:bold;font-size:25px;position:absolute;left:0;top:0;}
.myNewsletters.variant1 input[type=checkbox]:not(:checked) + label:before{animation-name:toCross;animation-duration: 0.5s;}
.myNewsletters.variant1 input[type=checkbox]:not(:checked) + label:hover:before{animation-name:toPlus;animation-duration: 0.5s;transform:rotate(90deg);-webkit-transform:rotate(90deg);}
.myNewsletters.variant1 input[type=checkbox]:checked + label:before{margin-top:3px;content:"";width:13px;height:9px;border-style:none none solid solid;border-width:3px;float:left;transform:rotate(-45deg); -webkit-transform:rotate(-45deg); border-color:$primary-color;}
@keyframes toPlus {
    from {transform:rotate(45deg); -webkit-transform: rotate(45deg);}
    to {transform:rotate(90deg); -webkit-transform: rotate(90deg);}
}
@keyframes toCross {
    from {transform:rotate(90deg); -webkit-transform: rotate(90deg);}
    to {transform:rotate(45deg); -webkit-transform: rotate(45deg);}
}
.myNewsletters.variant1 input.MailinglistSubscriptionsButton{@include button;}