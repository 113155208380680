@charset "UTF-8";

.meetingsSmall.variant3{
  article{
    margin-bottom:$margin-normal;
    padding-bottom:$padding-normal;
    border-bottom:1px solid $border-color;
    overflow:hidden;
    img{
      max-height:75px;
      margin:0 0 $margin-normal $margin-normal;
    }
  }
}