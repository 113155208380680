@charset "UTF-8";

.PersonAddFunction.variant1 .fout {font-style:italic; position:relative; top:-17px; margin-left:2px;}
.PersonAddFunction.variant1 .section_title {font-weight:bold;}

.PersonAddFunction.variant1 .input_container input.hasDatepicker {float:left;width:calc(100% - 45px);margin-right:5px;}
.PersonAddFunction.variant1 .input_container .ui-datepicker-trigger {cursor:pointer; background: url(/img/1/components/datepickerIcon.png) no-repeat; width: 20px; height: 40px; padding-left: 40px;}
.PersonAddFunction.variant1 .CrmSiteProfileMutationsAddFunctionRenderControl_instellenvoorprofiel {position:relative; min-height:63px; clear:both;}
.PersonAddFunction.variant1 .CrmSiteProfileMutationsAddFunctionRenderControl_instellenvoorprofiel .label_container {position:absolute; left:20px; top:10px;}
.PersonAddFunction.variant1 .CrmSiteProfileMutationsAddFunctionRenderControl_instellenvoorprofiel .input_container {position:absolute; left:0; top:10px;}
.PersonAddFunction.variant1 .add_function_functioninfo_container {margin-top:30px;}